import { Controller } from "stimulus";
//import Rails from "@rails/ujs";
export default class extends Controller {
  static targets = ["output"];

  connect() {}
  selectimage(e) {
    let id_field = document.getElementsByClassName("image-id")[0];
    let url_field = document.getElementsByClassName("image-url")[0];
    let url = `https://control.isimulate.community/${
      e.target.closest(".list-group-item").dataset.imageurl
    }`;
    let img_id = e.target.closest(".list-group-item").dataset.imageid;
    id_field.value = img_id;
    url_field.value = url;
    let img_tag = document.createElement("img");
    img_tag.src = url;
    img_tag.width = 200;
    img_tag.height = 200;
    document.getElementById("isim-patient-image").innerHTML = "";
    document.getElementById("isim-patient-image").appendChild(img_tag);
  }
}
