import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "fieldExperienceId",
    "siteSelector",
    "preceptorSelector",
    "links",
    "template",
    "schedule",
  ];

  // domainSelect(e) {
  //   if (e.target.value == "Hospital Clinical") {
  //     this.get_hospital_clinical_sites(function(result) {
  //       let list_of_sites = result;
  //       let site_selector = document.createElement("SELECT");
  //       let blankOption = document.createElement("option");
  //       let prompt = document.createTextNode("Select Site");
  //       blankOption.appendChild(prompt);
  //       site_selector.appendChild(blankOption);
  //       site_selector.classList.add("form-control");
  //       site_selector.classList.add("select");
  //       for (let i=0 ; i< list_of_sites.length; i++) {

  //       }
  //       e.target.parentNode.appendChild(site_selector);

  //     });
  //   } else {
  //     console.log("This is NOT a clinical");
  //   }
  // }

  StudentCanAddPreceptorSelect(event) {
    if (event.target.value == "true") {
      let hidden_div = document.getElementsByClassName(
        "hidden-domain-limit"
      )[0];
      hidden_div.className = "domain-limit";
    } else {
      document.getElementsByClassName("domain-limit")[0].className =
        "hidden-domain-limit";
    }
  }

  add_schedule(event) {
    event.preventDefault();
    var content = this.scheduleTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    );
    console.log(content);
    this.linksTarget.insertAdjacentHTML("beforebegin", content);
  }

  remove_schedule(event) {
    event.preventDefault();
    let wrapper = event.target.closest(".nested-fields");
    console.log(wrapper);
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = "none";
    }
  }

  add_association(event) {
    //console.log("ADDING ASSOCIATION")
    event.preventDefault();
    let selector = event.target
      .closest(".well")
      .getElementsByClassName("site-selector")[0];

    let siteName = selector.options[selector.selectedIndex].text;
    var content = event.target
      .closest(".well")
      .getElementsByClassName("template-fields")[0]
      .innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    //var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime() )

    event.target.insertAdjacentHTML("beforebegin", content);
    let elementList = event.target
      .closest(".well")
      .getElementsByClassName("nested-fields");

    this.setPreceptorsBasedOnSite(event, siteName, elementList);
  }

  set_preceptors_by_site(callback) {
    Rails.ajax({
      url: "/site_preceptors",
      type: "get",
      dataType: "json",
      success: callback,
    });
  }

  get_hospital_clinical_sites(callback) {
    Rails.ajax({
      url: "/hospital_clinical_sites",
      type: "get",
      dataType: "json",
      success: callback,
    });
  }

  connect() {
    console.log("Hello, Preceptorship!", this.element);
    let preceptorList = this.set_preceptors_by_site(function(result) {
      console.log(result);
    });
  }

  preceptorSelect(e) {
    console.log(e.target.parentNode.parentNode.parentNode.parentNode);
  }

  openModal() {
    console.log(event.target.parentNode.parentNode);
    let e = event.target
      .closest(".well")
      .getElementsByClassName("site-selector")[0];
    console.log(e);
    e.selectedIndex = -1;

    Rails.ajax({
      url: `/populate_preceptor_modal.js`,
      type: "get",
      success: function() {
        $("#preceptorModal").modal("show");
      },
    });
  }

  deletePreceptor(ev) {
    ev.preventDefault();
    let preceptorFields = event.target.parentNode.parentNode.parentNode;
    console.log(preceptorFields);
    //console.log(event.target.parentNode.parentNode.parentNode.getElementsByClassName("delete-field")[0].value=true)
    //let e = ev.target.parentNode.style.display="none"
  }

  siteSelect(e) {
    let elementList = e.target.parentNode.parentNode.getElementsByClassName(
      "nested-fields"
    );
    let siteName = e.target.options[e.target.selectedIndex].text;
    this.setPreceptorsBasedOnSite(e, siteName, elementList);
  }

  setPreceptorsBasedOnSite(e, siteName, elementList) {
    siteName = siteName;
    elementList = elementList;
    let preceptorList = this.set_preceptors_by_site(function(preceptorList) {
      let unsorted_preceptor_array = preceptorList.find(
        (x) => x.name === siteName
      ).preceptors;
      let preceptor_array = unsorted_preceptor_array.sort((a, b) =>
        a.last_name > b.last_name ? 1 : b.last_name > a.last_name ? -1 : 0
      );
      console.log("PRECEPTOR ARRAY", preceptor_array);
      if (elementList.length > 0) {
        for (var i = 0; i < elementList.length; i++) {
          let preceptor_selector = elementList[i].querySelector(
            ".preceptor-selector"
          );
          if (preceptor_selector !== null) {
            let persisted = preceptor_selector.dataset.persisted;
            if (persisted != "true" && preceptor_selector.selectedIndex < 1) {
              preceptor_selector.innerHTML = "";
              let blankOption = document.createElement("option");
              let prompt = document.createTextNode("Select Preceptor");
              blankOption.appendChild(prompt);
              preceptor_selector.appendChild(blankOption);
              for (var j = 0; j < preceptor_array.length; j++) {
                let preceptor = preceptor_array[j];
                let newOption = document.createElement("option");
                newOption.setAttribute("value", preceptor.id);
                let preceptor_name = document.createTextNode(
                  `${preceptor.last_name}, ${preceptor.first_name}`
                );
                newOption.appendChild(preceptor_name);
                preceptor_selector.appendChild(newOption);
              }
            }
          }
        }
      }
    });
  }
}
