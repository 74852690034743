import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  connect() {
    console.log("Hello, Check!", this.element);
  }
  handleClick(e) {
    let checkbox = this.checkboxTarget;
    let listItem = this.element;
    if (e.target.innerHTML == "Confirm") {
      checkbox.checked = true;
      listItem.classList.add("list-group-item-success");
      listItem.classList.remove("list-group-item-danger");
      e.target.innerHTML = "Undo";
      e.target.classList.remove("btn-success");
      e.target.classList.add("btn-link");
      e.target.blur();
    } else {
      checkbox.checked = false;
      listItem.classList.add("list-group-item");
      listItem.classList.remove("list-group-item-success");
      e.target.innerHTML = "Confirm";
      e.target.classList.remove("btn-link");
      e.target.classList.add("btn-success");
    }
    console.log("checkbox", this.checkboxTarget);
    console.log("list-item", this.element);
  }
}
