// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {
    console.log("Hello, preceptor checks");
  }

  accept(event) {
    console.log("ACCEPTED");
    let list_item = event.target.closest("li");
    let candidate_success = list_item.querySelector(
      ".candidate-selected"
    ).innerText;
    list_item.querySelector(".preceptor-success-level").value =
      candidate_success;
    list_item.querySelector(".approved-by-preceptor").value = "true";
    list_item.classList.add("list-group-item-success");
    event.target.classList.add("hidden");
    list_item.querySelector(".btn-danger").classList.add("hidden");
    list_item.querySelector(".btn-link").classList.remove("hidden");
    console.log("PRECEPTOR SUCCESS", preceptor_success);
    console.log("LIST ITEM", list_item);
  }

  deny(event) {
    let list_item = event.target.closest("li");
    let candidate_success = list_item.querySelector(
      ".candidate-selected"
    ).innerText;
    let options = ["Successful", "Needs Work", "Not Done"];
    let leftover = options.filter((option) => {
      return option !== candidate_success;
    });
    console.log("DENIED");
    let myModal = $("#disagree-modal");
    document.getElementById("disagree-modal").dataset.compId = list_item.id;
    document.getElementById("button-choices").innerHTML = "";
    document.getElementById("precept-deny-modal-comments").value = "";
    for (let i = 0; i < leftover.length; i++) {
      myModal
        .find("#button-choices")
        .append(
          `<div class="btn button-not-selected disagree-button" data-action="click->preceptor-competency-checks#setPreceptorValue" data-value="${leftover[i]}">${leftover[i]}</div>`
        );
    }

    myModal.modal("show"); // Using jQuery to show the modal
  }

  setPreceptorValue(event) {
    let selected_value = event.target.innerText;
    document.getElementById("precept-deny-modal-value").value = selected_value;
    let buttons = document.querySelectorAll(".disagree-button");
    console.log("BUTTONS", buttons);
    buttons.forEach((button) => {
      button.classList.remove("button-selected");
      button.classList.add("button-not-selected");
    });
    event.target.classList.remove("button-not-selected");
    event.target.classList.add("button-selected");
    console.log("SET VALUE", event.target.innerText);
  }
  submitPortal(event) {
    event.preventDefault();
    let modal_element = this.element;
    let comments = modal_element.querySelector("textarea").value;
    let preceptor_value = modal_element.querySelector("input").value;
    console.log("SUBMITTED", comments, preceptor_value);
    let myModal = $("#disagree-modal"); // Using jQuery to select the modal
    let target_id = modal_element.dataset.compId;
    let list_item = document.getElementById(target_id);
    list_item.classList.add("list-group-item-warning");
    list_item.querySelector(".preceptor-success-level").value = preceptor_value;
    let preceptor_selected_label =
      list_item.getElementsByClassName("preceptor-selected")[0];
    preceptor_selected_label.innerText = preceptor_value;
    list_item
      .querySelector(".preceptor-selected-wrapper")
      .classList.remove("hidden");
    list_item.querySelector(".approved-by-preceptor").value = "true";
    let textarea = list_item.querySelector("textarea");
    textarea.value = comments;
    textarea.hidden = false;
    myModal.modal("hide"); // Using jQuery to show the modal
    list_item.querySelector(".btn-danger").classList.add("hidden");
    list_item.querySelector(".btn-link").classList.remove("hidden");
    list_item.querySelector(".btn-success").classList.add("hidden");
  }
  undo() {
    let list_item = event.target.closest("li");
    list_item.classList.remove("list-group-item-warning");
    list_item.classList.remove("list-group-item-success");
    list_item.querySelector("input").value = "";
    list_item
      .querySelector(".preceptor-selected-wrapper")
      .classList.add("hidden");
    list_item.querySelector("textarea").hidden = true;
    list_item.querySelector("textarea").value = "";
    list_item.querySelector(".btn-danger").classList.remove("hidden");
    list_item.querySelector(".btn-link").classList.add("hidden");
    list_item.querySelector(".btn-success").classList.remove("hidden");
  }
}
