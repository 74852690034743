import { func } from "prop-types";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];
  connect() {
    this.TotalScore();
  }
  TotalScore = (e) => {
    if (e && e.target.value > 100) {
      alert("value must be less than 100");
      e.target.value = "";
      e.target.focus();
      return;
    }
    let inputs = document.getElementsByClassName("score-input");
    let input_array = Array.from(inputs);
    let max_score = input_array.length * 100;
    var total = 0.0;
    input_array.forEach((element) => {
      console.log("Element Value: ", parseInt(element.value));
      if (element.value) {
        total = parseInt(element.value) + total;
      }
    });
    let score = parseFloat((total / max_score) * 100).toFixed(2);
    let score_input = document.getElementById("total-score-field");
    const score_field = document.getElementById("total-score");
    console.log("SCORE FIELD: ", score_field);
    score_field.innerHTML = `<h2>${score}%</h2>`;
    score_input.value = score;
    console.log("TOTALED: ", score);
  };
}
