// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {
    console.log("Hello, Skill Ratings!", this.element);
    this.updateOverallScore();
    this.checkCompletion();
  }
  levelSelected(event) {
    let level = event.target.dataset.level;
    if (level == "Undo") {
      this.undoLevel(event);
    } else {
      let parent_list_group = event.target.closest(".skill-item");
      let weight = parseFloat(parent_list_group.dataset.weightedscore);
      console.log("Weight", weight);
      let proficiency_field =
        parent_list_group.querySelector(".proficiency-field");
      let weighted_score_field = parent_list_group.querySelector(
        ".weighted-score-field"
      );
      let weighted_score = 0;
      if (level == "Done") {
        weighted_score = weight;
      } else if (level == "Needs Work") {
        weighted_score = weight / 2;
      } else if (level == "Not Done") {
        weighted_score = 0;
      }
      proficiency_field.value = level;
      weighted_score_field.value = weighted_score;
      //remove button and add undo button
      let buttons = parent_list_group.querySelectorAll(".sk-rating-btn");
      buttons.forEach((button) => {
        button.classList.add("hidden");
      });
      let undo_button = parent_list_group.querySelector(".undo-btn");
      undo_button.classList.remove("hidden");
      let completed_statement = parent_list_group.querySelector(
        ".completed-statement"
      );
      completed_statement.innerHTML = `${level}, weighted score: ${weighted_score}`;
      console.log("Level", level);
      console.log("comp statement", completed_statement);
      if (level == "Done") {
        completed_statement.className = "completed-statement text-success";
      } else if (level == "Needs Work") {
        completed_statement.className = "completed-statement text-warning";
      } else if (level == "Not Done") {
        completed_statement.className = "completed-statement text-danger";
      }
    }

    this.updateOverallScore();
    this.checkCompletion();
  }
  undoLevel(event) {
    let parent_list_group = event.target.closest(".skill-item");
    let proficiency_field =
      parent_list_group.querySelector(".proficiency-field");
    let weighted_score_field = parent_list_group.querySelector(
      ".weighted-score-field"
    );
    let buttons = parent_list_group.querySelectorAll(".sk-rating-btn");
    buttons.forEach((button) => {
      button.classList.remove("hidden");
    });
    let undo_button = parent_list_group.querySelector(".undo-btn");
    undo_button.classList.add("hidden");
    proficiency_field.value = "";
    weighted_score_field.value = 0;
    this.updateOverallScore();
    this.checkCompletion();
    let completed_statement = parent_list_group.querySelector(
      ".completed-statement"
    );
    completed_statement.innerHTML = "";
  }
  updateOverallScore(event) {
    let overall_score = 0.0;
    let weighted_scores = document.querySelectorAll(".weighted-score-field");
    weighted_scores.forEach((weighted_score) => {
      if (weighted_score.value == "") {
        weighted_score.value = 0;
      }
      console.log("score", parseFloat(weighted_score.value));
      overall_score += parseFloat(weighted_score.value);
      console.log("overall score", overall_score);
    });
    let overall_score_field = document.querySelector("#score-area");
    let all_list_items = document.querySelectorAll(".skill-item");
    let total_available_score = 0;
    all_list_items.forEach((list_item) => {
      total_available_score += parseFloat(list_item.dataset.weightedscore);
    });
    let overall_score_percentage =
      (overall_score / total_available_score) * 100;
    overall_score_percentage = overall_score_percentage.toFixed(2);
    overall_score_field.innerHTML = `Overall Score: ${overall_score_percentage}% - ${overall_score} / ${total_available_score} points`;
  }
  checkCompletion(event) {
    //check if all proficiencies have a value
    let all_selected = true;
    let all_proficiency_fields =
      document.querySelectorAll(".proficiency-field");
    all_proficiency_fields.forEach((proficiency_field) => {
      if (!proficiency_field.value) {
        all_selected = false;
      }
    });
    //enable or disable submit button type=submit
    const submit_button = document.querySelector("[type=submit]");
    submit_button.disabled = !all_selected;
  }
  submitAllAsDone(event) {
    let all_list_items = document.querySelectorAll(".skill-item");
    all_list_items.forEach((list_item) => {
      let proficiency_field = list_item.querySelector(".proficiency-field");
      let weighted_score_field = list_item.querySelector(
        ".weighted-score-field"
      );
      let completed_statement = list_item.querySelector(".completed-statement");
      let buttons = list_item.querySelectorAll(".sk-rating-btn");
      buttons.forEach((button) => {
        button.classList.add("hidden");
      });
      let undo_button = list_item.querySelector(".undo-btn");
      undo_button.classList.remove("hidden");
      proficiency_field.value = "Done";
      weighted_score_field.value = list_item.dataset.weightedscore;
      completed_statement.innerHTML = `Done, weighted score: ${list_item.dataset.weightedscore}`;
      completed_statement.className = "completed-statement text-success";
    });
    this.updateOverallScore();
    this.checkCompletion();
  }
  undoAll(event) {
    let all_list_items = document.querySelectorAll(".skill-item");
    all_list_items.forEach((list_item) => {
      let proficiency_field = list_item.querySelector(".proficiency-field");
      let weighted_score_field = list_item.querySelector(
        ".weighted-score-field"
      );
      let buttons = list_item.querySelectorAll(".sk-rating-btn");
      buttons.forEach((button) => {
        button.classList.remove("hidden");
      });
      let undo_button = list_item.querySelector(".undo-btn");
      undo_button.classList.add("hidden");
      proficiency_field.value = "";
      weighted_score_field.value = 0;
      let completed_statement = list_item.querySelector(".completed-statement");
      completed_statement.innerHTML = "";
    });
    this.updateOverallScore();
    this.checkCompletion();
  }
}
