// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "result",
    "successBtn",
    "needsWorkBtn",
    "undoBtn",
    "undoLabel",
    "approved",
  ];
  static SUCCESS_LEVELS = {
    success: {
      name: "Successful",
      class: "list-group-item list-group-item-success revealed comp-item",
    },
    needs_work: {
      name: "Needs Work",
      class: "list-group-item comp-item revealed list-group-item-warning",
    },
    undo: { name: "Not done", class: "list-group-item revealed comp-item" },
  };
  connect() {
    let item = this.element;
    let value = item.dataset.setto;
    if (value && value !== "Not done") {
      console.log(value);
      let levels = this.constructor.SUCCESS_LEVELS;
      let levelKey = Object.keys(levels).find(
        (key) => levels[key].name === value
      );
      let level = levels[levelKey];
      console.log("LEVEL", level);
      this.setValue(value);
      this.set_visibility_and_class(levelKey, value);
    }
  }

  changed(e) {
    let button = e.target.closest("button");
    let level = button.dataset.level;
    let value = this.constructor.SUCCESS_LEVELS[level]["name"];
    this.setValue(value);
    this.set_visibility_and_class(level, value);
    document.getElementById("page-wrap").dataset.edited = "true";
  }

  setValue(value) {
    this.resultTarget.value = value || level;
    this.checkedCount();
    this.approvedTarget.value = "false";
  }
  set_visibility_and_class(level, value) {
    if (level !== "undo") {
      this.undoLabelTarget.innerHTML = value;
      this.element.querySelectorAll(".set-btn").forEach((element) => {
        element.classList.add("hidden");
      });
      this.undoBtnTarget.classList.remove("hidden");
    } else {
      this.undoLabelTarget.innerHTML = "";
      this.element.querySelectorAll(".set-btn").forEach((element) => {
        element.classList.remove("hidden");
      });
      this.undoBtnTarget.classList.add("hidden");
    }
    this.element.className = this.constructor.SUCCESS_LEVELS[level].class;
  }
  checkedCount() {
    let type = this.element.dataset.type;
    let count = 0;
    document.querySelectorAll("input.result").forEach((element) => {
      if (element.value !== "Not done") {
        count += 1;
      }
    });
    document.querySelector(
      "input[name=commit]"
    ).value = `Submit ${count} ${type}`;
    console.log("CHECKED COUNT", count);
    return count;
  }
}
