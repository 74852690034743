// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {
      console.log("Hello, Audit Competencies!", this.element);
    this.updateCompCheckCount = this.updateCompCheckCount.bind(this);
    this.setSimVsRating = this.setSimVsRating.bind(this);
    this.postResult = this.postResult.bind(this);
    this.disqualify = this.disqualify.bind(this);
    this.approve = this.approve.bind(this);
    this.openModal = this.openModal.bind(this);
    this.updateUiToApproved = this.updateUiToApproved.bind(this);
    this.updateUiToDisqualified = this.updateUiToDisqualified.bind(this);
    this.updateUiToUndone = this.updateUiToUndone.bind(this);
    this.undo = this.undo.bind(this);
      this.approveAll = this.approveAll.bind(this);
      this.updateCompCheckCount();
  }
    
updateCompCheckCount() {

    let successfulChecks = document.querySelectorAll(".list-group-item-success.comp-li")
    let disqualifiedChecks = document.querySelectorAll(".list-group-item-danger.comp-li")
    let availableChecks = document.querySelectorAll(".comp-li")
    let count = successfulChecks.length;

    let auditCompApprovedElement = document.getElementById("audit-comp-approved");

    auditCompApprovedElement.innerHTML = `${count} Approved, ${disqualifiedChecks.length} Disqualified, ${availableChecks.length - count - disqualifiedChecks.length} Available`;
}

setSimVsRating(e) {
    let simVsRating = e.target.innerHTML
    let sim_view = document.getElementById("audit-sim-view")
    let rating_view = document.getElementById("audit-rating-view")
    console.log(simVsRating)
    if (simVsRating == "Simulation") {
        sim_view.classList.remove("hidden")
        rating_view.classList.add("hidden")
    } else {
        sim_view.classList.add("hidden")
        rating_view.classList.remove("hidden")
    }
}


postResult(parent, outcome, callback) {
    let checked_competency_id = parent.dataset.ccId
    let audit_id = parent.dataset.auditId
    let reason = document.getElementById("comment").value
    let payload = {
        audited_competency_check: {
            checked_competency_id: checked_competency_id,
            outcome: outcome,
            audit_id: audit_id,
            reason: reason
        }
    }
    fetch("/audited_competency_checks", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify(payload)
    }).then(response => {
        if (response.ok) {
            let json = response.json().then(json => {
            console.log("response", json)
            let id = json.id
            callback(parent, id)
                console.log("success")
            }
        )
        } else {
            console.log("fail")
        }
    }
    )
}
disqualify(e) {
    //e.preventDefault()
    this.postResult(e.target, "disqualified", this.updateUiToDisqualified)
    let listItem = document.getElementById(e.target.dataset.liId)
    listItem.querySelector(".disq-row").classList.remove("hidden")
    listItem.querySelector(".disqualify-reason").innerHTML = document.getElementById("comment").value
    document.getElementById("comment").value = ""
    
}  
    approve(e) {
        this.postResult(e.target.closest(".list-group-item"), "approved", this.updateUiToApproved)
    }   
openModal(e) {
    e.preventDefault()
    let data_set = { ...e.target.closest(".list-group-item").dataset }
    const modal = document.getElementById("myModal")
    let saveButton = modal.querySelector("#modal-save-btn")
    Object.assign(saveButton.dataset, data_set)
    saveButton.dataset.liId = e.target.closest(".list-group-item").id
    if (modal) {
        $(modal).modal('show')
    }
}
    updateUiToApproved(parent, rec_id) {
        let listItem = parent
        let undoButton = listItem.querySelector(".undo")
        let approveButton = listItem.querySelector(".audit-approve")
        let disqualifyButton = listItem.querySelector(".audit-disqualify")
        listItem.classList.add("list-group-item-success")
        undoButton.classList.remove("hidden")
        approveButton.classList.add("hidden")
        disqualifyButton.classList.add("hidden")
        listItem.dataset.auditedCheckId = rec_id
        this.updateCompCheckCount()
    }

updateUiToDisqualified(e, rec_id) {
    let listItem_id = e.dataset.liId 
    let listItem = document.getElementById(listItem_id)
    let undoButton = listItem.querySelector(".undo")
    let approveButton = listItem.querySelector(".audit-approve")
    let disqualifyButton = listItem.querySelector(".audit-disqualify")
    listItem.classList.add("list-group-item-danger")
    undoButton.classList.remove("hidden")
    approveButton.classList.add("hidden")
    disqualifyButton.classList.add("hidden")
    listItem.dataset.auditedCheckId = rec_id
    const modal = document.getElementById("myModal")

    $(modal).modal('hide')
    this.updateCompCheckCount()
}

updateUiToUndone(e) {
    let listItem = e.target.closest(".list-group-item")
    let undoButton = listItem.querySelector(".undo")
    let approveButton = listItem.querySelector(".audit-approve")
    let disqualifyButton = listItem.querySelector(".audit-disqualify")
    listItem.classList.remove("list-group-item-danger")
    listItem.classList.remove("list-group-item-success")
    undoButton.classList.add("hidden")
    approveButton.classList.remove("hidden")
    disqualifyButton.classList.remove("hidden")
    listItem.removeAttribute("data-audited-check-id")
        listItem.querySelector(".disq-row").classList.add("hidden")
    listItem.querySelector(".disqualify-reason").innerHTML = ""
    this.updateCompCheckCount()
}
    
undo(e) {
    //delete the record
    let auditedCheckId = e.target.closest(".list-group-item").dataset.auditedCheckId
    fetch(`/audited_competency_checks/${auditedCheckId}`, {
        method: "DELETE",
        headers: {
            "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content
        }
    }).then(response => {
        if (response.ok) {
            this.updateUiToUndone(e)
        }
    })
    
}
approveAll() {
    let all_items = document.querySelectorAll(".list-group-item")
    all_items.forEach(item => {
        let auditedCheckId = item.dataset.auditedCheckId
        if (!auditedCheckId) {
            this.postResult(item, "approved", this.updateUiToApproved)
        }
    })
}
}
