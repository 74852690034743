import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["bar"];

  connect(e) {
    this.progressValue = this.element.dataset.progress;

    let totalValue = this.element.dataset.total;

    this.updateProgressBar(totalValue, this.progressValue);
  }

  updateProgressBar(total, progress) {
    console.log(total);
    const percentage = (progress / total) * 100;
    this.barTarget.style.transition = "width 1s ease-in-out";
    this.barTarget.style.width = `${percentage}%`;
  }
}
