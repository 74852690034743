import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "status", "timestamp", "button", "complete" ]
  connect() {
  	console.log($(this.completeTarget).hide());
  };
  reviewed(e) {
    e.preventDefault();
  	this.statusTarget.value="Complete";
  	console.log( this.buttonTarget.remove() );
  	console.log( $(this.completeTarget).fadeIn() );
}
}