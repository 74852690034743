import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["firstName", "lastName", "email", "emailPrefix"];
  static values = { url: String };

  connect() {
    console.log("Hello, Preceptor add");
    //this.getPreceptors();
  }

  siteChanged(e) {
    console.log("SITE CHASNGED", e.target);
    Rails.ajax({
      url: `/sites/${e.target.value}.json`,
      type: "get",
      success: function(r) {
        document.getElementById("email-domain").innerHTML = r.email_domain
          ? r.email_domain
          : "No email domain defined, can't add preceptor here.";
      },
    });
  }

  getPreceptors() {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        url: `/preceptors.json`,
        type: "get",
        success: function(r) {
          console.log("PRECEPTORS", r);
          resolve(r);
        },
        error: function(err) {
          reject(err);
        },
      });
    });
  }

  async check(e) {
    e.preventDefault();
    console.log("CHECKING");
    let firstName = this.firstNameTarget.value;
    let lastName = this.lastNameTarget.value;
    let email_domain;
    try {
      email_domain = document.getElementById("email-domain").innerHTML;
    } catch (err) {
      email_domain = null;
    }
    console.log("EMAIL DOMAIN", email_domain);
    try {
      let preceptors = await this.getPreceptors();
      let foundPreceptor;
      if (email_domain) {
        foundPreceptor = preceptors.find((p) => {
          let p_domain = `@${p.email.split("@")[1].toLowerCase()}`;
          console.log("DOM", p_domain);
          return (
            p.first_name.toLowerCase() === firstName.toLowerCase() &&
            p.last_name.toLowerCase() === lastName.toLowerCase() &&
            p_domain.toLowerCase().trim() === email_domain.toLowerCase().trim()
          );
        });
      } else {
        foundPreceptor = preceptors.find((p) => {
          return (
            p.first_name.toLowerCase() === firstName.toLowerCase() &&
            p.last_name.toLowerCase() === lastName.toLowerCase()
          );
        });
      }
      if (foundPreceptor) {
        console.log("FOUND", foundPreceptor);

        // Update the modal message
        $(".modal-body").html(
          `A preceptor was found in the system with that name and the email address <b>${foundPreceptor.email}</b>. Would you like to use this preceptor?`
        );

        // Show the modal
        $("#preceptorModal").modal("show");

        // Add event listener to the Yes button
        $(".yes-btn")
          .off("click")
          .on("click", () => {
            // Update the email field on the form
            if (email_domain) {
              this.emailPrefixTarget.value = `${
                foundPreceptor.email.split("@")[0]
              }`;
              this.emailPrefixTarget.closest("form").submit();
            } else {
              this.emailTarget.value = foundPreceptor.email;
              this.emailTarget.closest("form").submit();
            }

            // Proceed with the original form submission
            // Assuming the form is the closest ancestor of the email field

            // Close the modal
            $("#preceptorModal").modal("hide");
          });
        $(".no-btn")
          .off("click")
          .on("click", () => {
            // Proceed with the original form submission
            $(this.emailTarget.closest("form")).trigger("submit.rails");

            // Close the modal
            $("#preceptorModal").modal("hide");
          });
      } else {
        e.target.closest("form").submit();
      }
    } catch (err) {
      console.error(err);
    }
  }
}
