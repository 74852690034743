// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {
    console.log("Hello, Check All!", this.element);
  }
  check(e) {
    let status = e.target.dataset.status;
    let inputs = this.element.getElementsByClassName("approved-box");
    if (status === "APPROVE") {
      e.target.innerHTML = "UNDO APPROVE ALL";
      e.target.dataset.status = "UNDONE";
      for (var i = 0; i < inputs.length; i++) {
        let inp = inputs[i];
        inp.checked = true;
        inp.value = true;
        inp.closest("li").className = "list-group-item list-group-item-success";
      }
    } else {
      e.target.innerHTML = "APPROVE ALL";
      e.target.dataset.status = "APPROVE";
      for (var i = 0; i < inputs.length; i++) {
        let inp = inputs[i];
        inp.checked = false;
        inp.value = false;
        inp.closest("li").className = "list-group-item";
      }
    }

    console.log("INPUTS", inputs.length);
  }
}
