import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {
    console.log("Hello, Externak Auditor!");
  }

  cohortSelect(e) {
    this.clearList();
    let cohort_id = e.target.value;
    this.getCandidatesFromCohort(cohort_id);
  }

  clearList() {
    let paras = document.getElementsByClassName("candidate-li");
    while (paras[0]) {
      paras[0].parentNode.removeChild(paras[0]);
    }
  }

  getCandidatesFromCohort(cohort_id) {
    Rails.ajax({
      url: `/candidates_list/${cohort_id}.json`,
      type: "get",
      success: function(data) {
        let list = document.getElementById("cand-list");
        let header = document.getElementById("candidate-select-header");
        header.classList.remove("hidden");
        for (let i = 0; i < data.length; i++) {
          let rec = data[i];
          console.log("EACH", rec);
          let list_item = document.createElement("li");
          list_item.classList.add("list-group-item");
          let row = document.createElement("div");
          row.classList.add("row");
          let col_1 = document.createElement("div");
          col_1.classList.add("col-md-1");
          let col_2 = document.createElement("div");
          col_2.classList.add("col-md-11");
          list_item.classList.add("list-group-item");
          list_item.classList.add("candidate-li");
          let text = document.createTextNode(
            `${rec.last_name}, ${rec.first_name}`
          );
          let input = document.createElement("input");
          input.setAttribute("id", "candidate_ids_");
          input.setAttribute("name", "external_audit[candidates][]");
          input.setAttribute("value", rec.id);
          input.setAttribute("type", "checkbox");
          col_1.appendChild(input);
          col_2.appendChild(text);
          row.appendChild(col_1);
          row.appendChild(col_2);
          list_item.appendChild(row);
          list.appendChild(list_item);
          //<input checked="checked" id="receive_email" name="receive_email" type="checkbox" value="yes" />
        }
      },
    });
  }
}
