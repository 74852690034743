import { Controller } from "stimulus";
const SetAsMedication = (e) => {
  console.log("SETTING AS MEDICATION: ", e);
  let parent = e.parentNode;
  let to_unhide = parent.getElementsByClassName("medication-fields")[0];
  let to_remove = parent.getElementsByClassName("procedure-fields")[0];
  let to_set = parent.getElementsByClassName("type-field")[0];
  to_remove ? to_remove.remove() : "";
  to_unhide.classList.remove("hidden");
  parent.getElementsByClassName("select-fields")[0].classList.add("hidden");
  to_set.value = "medication";
};

const SetAsProcedure = (e) => {
  console.log("SETTING AS PROCEDURE: ", e);
  let parent = e.parentNode;
  let to_unhide = parent.getElementsByClassName("procedure-fields")[0];
  let to_remove = parent.getElementsByClassName("medication-fields")[0];
  let to_set = parent.getElementsByClassName("type-field")[0];
  to_remove ? to_remove.remove() : "";
  to_unhide.classList.remove("hidden");
  parent.getElementsByClassName("select-fields")[0].classList.add("hidden");
  to_set.value = "procedure";
};

export default class extends Controller {
  static targets = [];

  selectprocedure = (e) => {
    SetAsProcedure(e.target.parentNode);
  };
  selectmedication = (e) => {
    SetAsMedication(e.target.parentNode);
  };
  connect() {
    let procedure_fields = document.getElementsByClassName(
      "field_procedure_fields"
    );
    let i;
    for (i = 0; i < procedure_fields.length; i++) {
      let el = procedure_fields[i];
      let procedure_type = el.getElementsByClassName("type-field")[0].value;
      if (procedure_type === "procedure") {
        SetAsProcedure(el.getElementsByClassName("medication-fields")[0]);
      } else {
        SetAsMedication(el.getElementsByClassName("procedure-fields")[0]);
      }
    }
  }

  fieldentry = (e) => {
    $("#lookup").modal("show");
    console.log("Youre in", e.target);
    const element = e.target;
    let words = element.id.split("_");
    let subcategory = words[1];
    let url_subcat = "";
    if (subcategory == "location") {
      url_subcat = "A-Location";
    } else if (subcategory == "type") {
      url_subcat = "B-Type";
    } else if (subcategory == "mechanism") {
      url_subcat = "C-Mechanism";
    } else {
      return;
    }
    console.log("words", words);
    e.target.value = "";
    let url = `/patient_care_form_codes/${url_subcat}/get_by_subcategory`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        let ul = document.getElementById("code-list");
        console.log("UL: ", ul);
        ul.innerHTML = "";
        json.codes.forEach((code) => {
          let list_item = document.createElement("li");
          list_item.className = "list-group-item pf-code-list";
          list_item.setAttribute("data-action", "click->patient-form#select");
          list_item.setAttribute("data-code", `${code.code}`);
          list_item.setAttribute("data-attr", `${e.target.id}`);
          list_item.innerHTML = `${code.code} | ${code.name}`;
          ul.appendChild(list_item);
        });
        console.log(json.codes);
      });
  };

  select = (e) => {
    console.log("SELECT", e.target.getAttribute("data-code"));
    let field = document.getElementById(e.target.getAttribute("data-attr"));
    console.log("FIELD", field);
    field.value = e.target.getAttribute("data-code");
    $("#lookup").modal("hide");
  };
  selectprocedure = (e) => {
    let parent = e.target.parentNode.parentNode;
    let to_unhide = parent.getElementsByClassName("procedure-fields")[0];
    let to_remove = parent.getElementsByClassName("medication-fields")[0];
    let to_set = parent.getElementsByClassName("type-field")[0];
    to_remove.remove();
    to_unhide.classList.remove("hidden");
    e.target.parentNode.classList.add("hidden");
    to_set.value = "procedure";
  };
  selectmedication = (e) => {
    let parent = e.target.parentNode.parentNode;
    let to_unhide = parent.getElementsByClassName("medication-fields")[0];
    let to_remove = parent.getElementsByClassName("procedure-fields")[0];
    let to_set = parent.getElementsByClassName("type-field")[0];
    to_remove.remove();
    to_unhide.classList.remove("hidden");
    e.target.parentNode.classList.add("hidden");
    to_set.value = "medication";
  };
}
