import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {
    console.log("Hello, Global SKill Ratings!", this.element);
    this.updateUi();
    this.checkCompletion();
  }

  proficiencyLevelSet(e) {
    let level = e.target.dataset.level;
    console.log("Level", level);
    document.getElementById("skill_rating_proficiency").value = level;
    this.updateUi();
    this.checkCompletion();
  }

  coachingLevelSet(e) {
    let level = e.target.dataset.level;
    console.log("Level", level);
    document.getElementById("skill_rating_coaching_required").value = level;
    this.updateUi();
    this.checkCompletion();
  }
  updateUi() {
    let level = document.getElementById("skill_rating_proficiency").value;
    let coaching = document.getElementById(
      "skill_rating_coaching_required"
    ).value;
    let proficiency_buttons = document.querySelectorAll(".proficiency-button");
    let coaching_buttons = document.querySelectorAll(".coaching-button");
    proficiency_buttons.forEach((button) => {
      button.classList.remove("btn-selected");
    });
    coaching_buttons.forEach((button) => {
      button.classList.remove("btn-selected");
    });
    proficiency_buttons.forEach((button) => {
      if (button.dataset.level == level) {
        button.classList.add("btn-selected");
      }
    });
    coaching_buttons.forEach((button) => {
      if (button.dataset.level == coaching) {
        button.classList.add("btn-selected");
      }
    });
  }
  checkCompletion() {
    let proficiency = document.getElementById("skill_rating_proficiency").value;
    let coaching = document.getElementById(
      "skill_rating_coaching_required"
    ).value;
    const submit_button = document.querySelector("[type=submit]");
    if (proficiency && coaching) {
      submit_button.disabled = false;
    } else {
      submit_button.disabled = true;
    }
  }
}
