// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("Hello, GRS EDIT!");
  }
  valueSelected(event) {
    let short = event.target.dataset.section;
    let value = event.target.dataset.value;
    let definition = event.target.dataset.def;
    document.getElementById(`${short}_value`).value = value;
    document.getElementById(`set-def-${short}`).innerHTML = definition;
    let buttons = document.getElementsByClassName(`${short}-button`);
    console.log("BUTTONS", buttons);
    Array.from(buttons).forEach((button) => {
      button.classList.remove("grs-button-active");
      button.classList.add("grs-button-non-active");
    });
    event.target.classList.remove("grs-button-non-active");
    event.target.classList.add("grs-button-active");
    let completed = this.checkIfComplete();
    if (completed) {
      let save_button = document.getElementById("form-submit");
      save_button.disabled = false;
      document.getElementById("mandatory-text").innerHTML = "";
    }
  }
  checkIfComplete() {
    let domain_array = ["sa", "pa", "hg", "dm", "ru", "ps", "co"];
    let complete = true;
    for (let i = 0; i < domain_array.length; i++) {
      let short = domain_array[i];
      let value = document.getElementById(`${short}_value`).value;
      if (value == "") {
        complete = false;
      }
    }
    return complete;
  }
}
