import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.classList.add("flip-container");
    this.element.querySelector("#page_one").classList.add("flipper", "front");
    this.element.querySelector("#page_two").classList.add("flipper", "back");

    this.touchStartX = null;
    this.element.addEventListener(
      "touchstart",
      (e) => this.touchStart(e),
      false
    );
    this.element.addEventListener("touchend", (e) => this.touchEnd(e), false);
    this.element.addEventListener(
      "touchmove",
      (e) => {
        e.preventDefault();
      },
      { passive: false }
    );
  }

  switchTab(event) {
    event.preventDefault();
    this.element.classList.toggle("active");
    window.scrollTo(0, 0);
  }

  touchStart(e) {
    this.touchStartX = e.touches[0].clientX;
  }

  touchEnd(e) {
    let touchEndX = e.changedTouches[0].clientX;
    let diffX = this.touchStartX - touchEndX;

    if (Math.abs(diffX) > 100) {
      // if the swipe is long enough
      this.switchTab(e);
    }
  }
}
