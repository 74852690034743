import { Controller } from "stimulus";

export default class extends Controller {
  // 'mandatory' is a list of elements that must have a value,
  // 'button' is a single button element that will be disabled/enabled based on the 'mandatory' elements
  // Example HTML:
  // <input data-target="example.mandatory" type="text">
  // <button data-target="example.button">Submit</button>
  static targets = ["mandatory", "button"];

  // This function is automatically called when the controller is connected to the document
  connect() {
    this.checkMandatory = this.checkMandatory.bind(this);
    this.checkMandatory();
    console.log("Running");
    window.addEventListener("pageshow", this.checkMandatory);
  }

  disconnect() {
    window.removeEventListener("pageshow", this.checkMandatory);
  }

  // This function can be used to re-check mandatory fields when they're changed
  // Example HTML to call this function:
  // <input data-target="example.mandatory" data-action="input->example#changed" type="text">
  changed() {
    this.checkMandatory();
  }

  // This function checks if the mandatory fields have values and enables/disables the button accordingly
  checkMandatory(e) {
    // Get all the 'mandatory' target elements
    let mandatories = this.mandatoryTargets;
    for (var i = 0; i < mandatories.length; i++) {
      // If a 'mandatory' element is empty, disable the button
      console.log("MAND", mandatories[i].value);
      if (mandatories[i].tagName.toLowerCase() === "select") {
        console.log("SELCET MAND", mandatories[i].selectedIndex);
        if (mandatories[i].selectedIndex === 0 && mandatories[i].value === "") {
          this.buttonTarget.disabled = true;
          return;
        }
      } else {
        if (mandatories[i].value === "") {
          this.buttonTarget.disabled = true;
          return;
        }
      }
      // If all 'mandatory' elements have a value, enable the button
      this.buttonTarget.disabled = false;
    }
  }
}
