// alert_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.showConfirm(this.data.get("message"));
  }

  showConfirm(message) {
    if (message) {
      var userConfirmed = confirm(message);
      if (userConfirmed) {
        // User clicked OK, send confirmation to server
        fetch("/simulation/confirm", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector('[name="csrf-token"]')
              .content,
          },
          body: JSON.stringify({ confirm: true }),
        }).then(() => {
          // After confirmation, submit the form
          document.getElementById("sim_form").submit();
        });
      }
    }
  }
}
