// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("Hello, RATING SETUP!", this.element);
  }
  cohortSelected(event) {
    console.log("Cohort selected", event.target.value);
    const cohortId = event.target.value;
    const url = `/v1/cohort_candidates?cohort_id=${cohortId}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const candidates = data.candidates;
        const candidatesSelect =
          document.querySelector("#rating_candidate_id") ||
          document.querySelector("#skill_rating_candidate_id");
        candidatesSelect.innerHTML = "";
        const partnerSelect = document.querySelector("#rating_partner_id");
        if (partnerSelect) {
          partnerSelect.innerHTML = "";

          const defaultOptionPartner = document.createElement("option");
          defaultOptionPartner.text = "Select Partner (optional)";
          defaultOptionPartner.value = "";
          partnerSelect.appendChild(defaultOptionPartner);
        }
        const defaultOption = document.createElement("option");
        defaultOption.text = "Select Candidate";
        defaultOption.value = ""; // Typically, the value is empty for the prompt
        candidatesSelect.appendChild(defaultOption);

        // Loop through each candidate and create an option for each
        candidates.forEach((candidate) => {
          const option = document.createElement("option");
          option.value = candidate.id;
          option.text = `${candidate.last_name}, ${candidate.first_name}`;
          candidatesSelect.appendChild(option);
          if (partnerSelect) {
            partnerSelect.appendChild(option.cloneNode(true));
          }
        });
      });
    this.checkCompletion(event);
  }
  categorySelected(event) {
    console.log("Category selected", event.target.value);
    const categoryId = event.target.value;
    const url = `/v1/sims_by_category?sim_category_id=${categoryId}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const sims = data;
        const simsSelect = document.querySelector("#rating_simulation_id");
        simsSelect.innerHTML = "";
        const defaultOption = document.createElement("option");
        defaultOption.text = "Select Simulation";
        defaultOption.value = ""; // Typically, the value is empty for the prompt
        simsSelect.appendChild(defaultOption);
        // Loop through each candidate and create an option for each
        sims.forEach((sim) => {
          const option = document.createElement("option");
          option.value = sim.id;
          option.text = sim.name;
          simsSelect.appendChild(option);
        });
      });
    this.checkCompletion(event);
  }
  simSelected(event) {
    console.log("Simulation selected", event.target.value);
    const simId = event.target.value;
    const url = `/v1/simulations/${simId}/rating_tools_by_sim`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const categories = data;
        const categoriesSelect = document.querySelector(
          "#rating_rating_tool_id"
        );
        categoriesSelect.innerHTML = "";
        const defaultOption = document.createElement("option");
        defaultOption.text = "Select Rating Tool";
        defaultOption.value = ""; // Typically, the value is empty for the prompt
        categoriesSelect.appendChild(defaultOption);
        // Loop through each candidate and create an option for each
        categories.forEach((category) => {
          const option = document.createElement("option");
          option.value = category.id;
          option.text = category.name;
          categoriesSelect.appendChild(option);
        });
        this.checkCompletion(event);
      });
  }
  checkCompletion(event) {
    //get all selectors on page
    const all_selectors = document.querySelectorAll("select");
    //check if all have a value
    let all_selected = true;
    all_selectors.forEach((selector) => {
      if (!selector.value) {
        all_selected = false;
      }
    });
    //enable or disable submit button type=submit

    const submit_button = document.querySelector("[type=submit]");
    submit_button.disabled = !all_selected;
  }
  skillCategorySelected(event) {
    console.log("Skill Category selected", event.target.value);
    const categoryId = event.target.value;
    const url = `/v1/skills_by_category?id=${categoryId}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const skills = data;
        const skillsSelect = document.querySelector("#skill_rating_skill_id");
        skillsSelect.innerHTML = "";
        const defaultOption = document.createElement("option");
        defaultOption.text = "Select Skill";
        defaultOption.value = ""; // Typically, the value is empty for the prompt
        skillsSelect.appendChild(defaultOption);
        // Loop through each candidate and create an option for each
        skills["skills"].forEach((skill) => {
          const option = document.createElement("option");
          option.value = skill.id;
          option.text = skill.name;
          skillsSelect.appendChild(option);
        });
      });
    this.checkCompletion(event);
  }
}
