import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {}

  async toggle() {
    console.log("TOGGLE");
    let competency = await this.getCompetency(
      this.element.dataset.competencyId
    );
    let cohort_type = this.element.dataset.cohortType;
    let performance_environment;
    let definition;
    switch (cohort_type) {
      case "PCP Program":
        performance_environment = competency.pcpPerformanceEnvironment;
        definition = competency.pcp.replace(/\r\n\r\n/g, "\n");
        break;
      case "ACP Program":
        performance_environment = competency.acpPerformanceEnvironment;
        definition = competency.acp.replace(/\r\n\r\n/g, "\n");
        break;
      case "CCP Program":
        performance_environment = competency.ccpPerformanceEnvironment;
        definition = competency.ccp.replace(/\r\n\r\n/g, "\n");
        break;
      default:
        performance_environment = "CNO";
        definition = "CNO";
        break;
    }

    // Populate and show the modal
    document.getElementById(
      "performanceEnvironment"
    ).textContent = performance_environment;
    document.getElementById("definition").textContent = definition;
    document.getElementById("infoModalLabel").textContent =
      competency.nocp_name;
    $("#infoModal").modal("show");
  }

  getCompetency(id) {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        url: `/nocps/${id}.json`,
        type: "get",
        dataType: "json",
        success: function(json) {
          resolve(json);
        },
        error: function(err) {
          reject(err);
        },
      });
    });
  }
}
