import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["activityType", "activitySelector"];

  connect() {
    let activity_type_selector = this.activityTypeTarget;
    let value = activity_type_selector.value;
    this.changedType(null, value);
  }

  getSkills = () => {
    let selected_id = this.activitySelectorTarget.dataset.selected;
    let list = document.getElementsByClassName("activity-list")[0];
    list.innerHTML = null;
    let prompt = document.createElement("option");
    prompt.innerHTML = "Select Skill";
    list.appendChild(prompt);
    let url = `/self_directed_skills.json`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        json.skills.forEach((s) => {
          let opt = document.createElement("option");
          opt.value = s.id;
          opt.innerHTML = s.name;
          if (s.id == selected_id) {
            opt.selected = true;
          }
          list.appendChild(opt);
        });
      });
  };

  getLifts = () => {
    let list = document.getElementsByClassName("activity-list")[0];
    list.innerHTML = null;
    let selected_id = this.activitySelectorTarget.dataset.selected;
    let prompt = document.createElement("option");
    prompt.innerHTML = "Select Lift";
    list.appendChild(prompt);
    let url = `/lifts.json`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        json.forEach((s) => {
          let opt = document.createElement("option");
          opt.value = s.id;
          opt.dataset.def = s.description;
          opt.innerHTML = s.name;
          if (s.id == selected_id) {
            opt.selected = true;
          }
          list.appendChild(opt);
        });
      });
  };

  changedType(e, initialLoad = false) {
    let value = initialLoad ? initialLoad : e.target.value;
    console.log("changed", value);
    let act_field = document.getElementsByClassName("activity-field")[0];
    let act_id_field = document.getElementsByClassName("activity-id-field")[0];
    let lift_partner = document.getElementsByClassName("lifting-partner")[0];
    let weight = document.getElementsByClassName("weight")[0];
    console.log("FIELD", act_id_field);
    if (value == 1) {
      this.getSkills();
      act_field.classList.add("hidden");
      act_id_field.classList.remove("hidden");
      act_field.value = "";
      lift_partner.classList.add("hidden");
      weight.classList.add("hidden");
      lift_partner.value = "";
      weight.value = "";
    } else if (value == 3) {
      this.getLifts();
      act_field.classList.add("hidden");
      act_field.value = "";
      act_id_field.classList.remove("hidden");
      lift_partner.classList.remove("hidden");
      weight.classList.remove("hidden");
    } else if (value == 2) {
      act_field.classList.remove("hidden");
      act_id_field.classList.add("hidden");
      act_id_field.value = "";
      lift_partner.classList.add("hidden");
      weight.classList.add("hidden");
      lift_partner.value = "";
      weight.value = "";
    }
  }

  changedActivity(e) {
    let def = e.target.options[e.target.selectedIndex].dataset.def;
    let def_area = document.getElementById("activity-def");
    def_area.innerHTML = def ? def : "";
    def_area.className = def ? "" : "hidden";
    document.getElementsByClassName("activity-title")[0].innerHTML = "";
  }
}
