import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["approved"];

  connect() {
    if (this.data.get("approved") == "false") {
      this.setButtonsForNotApproved();
      if (document.getElementsByClassName("approved")[0] != undefined) {
        this.removeApprovedDiv();
      }
    } else {
      this.removeApprovedDiv();
      this.setButtonsForNotApproved();
    }
  }

  SetAsReviewed() {
    event.preventDefault();
    let approved_field = this.approvedTarget;
    approved_field.value = this.data.get("user");
    this.addApprovedDiv();
    this.setButtonsForApproved();
  }

  UnsetAsReviewed() {
    event.preventDefault();
    this.approvedTarget.value = 0;
    this.removeApprovedDiv();
    this.setButtonsForNotApproved();
  }

  removeApprovedDiv() {
    let approved_div = document.getElementsByClassName("approved")[0];
    approved_div.remove();
  }

  addApprovedDiv() {
    let approved_div = document.createElement("div");
    approved_div.className = "approved";
    approved_div.innerText = "Approved!";
    document.getElementById("review").appendChild(approved_div);
  }

  setButtonsForApproved() {
    let undo_button = document.getElementsByClassName("undo-button")[0];
    let approve_button = document.getElementsByClassName("approve-button")[0];
    approve_button.style.display = "none";
    undo_button.style.display = "block";
  }

  setButtonsForNotApproved() {
    let undo_button = document.getElementsByClassName("undo-button")[0];
    let approve_button = document.getElementsByClassName("approve-button")[0];
    approve_button.style.display = "block";
    undo_button.style.display = "none";
  }
}
