import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["value", "valuetext"];
  connect() {
    console.log("Hello, NON GRS");
    this.checkIfComplete();
  }
  valueChange(e) {
    console.log("VALUE CHANGE", e.target);
    let buttons = this.element.getElementsByClassName("grs-select");
    let text_buttons = this.element.getElementsByClassName("grs-select-text");
    let definition_element = this.element.getElementsByClassName(
      "non-grs-anchor-definition"
    )[0];
    let new_value = e.target.innerHTML;
    if (e.target.dataset.type === "number") {
      console.log("NUMBER CHANGE");
      this.valueTarget.value = new_value;
      this.valuetextTarget.value = null;
    } else if (e.target.dataset.type === "text") {
      console.log("TEXT CHANGE");
      this.valuetextTarget.value = new_value;
      this.valueTarget.value = null;
    }
    this.getDefinition(
      this.element.dataset.scale,
      new_value,
      definition_element
    );
    for (let i = 0; i < buttons.length; i++) {
      let button = buttons[i];
      button.className =
        button.innerHTML === new_value
          ? "grs-select grs-value-active"
          : "grs-select grs-value";
    }
    for (let i = 0; i < text_buttons.length; i++) {
      let button = text_buttons[i];
      button.className =
        button.innerHTML === new_value
          ? "grs-select-text grs-value-text-active"
          : "grs-select-text grs-value";
    }
    this.checkIfComplete();
    // console.log("VALUE FIELD", (this.valueTarget.value = new_value));
  }
  multilineValueChange(e) {
    let buttons = this.element.getElementsByClassName("grs-select");
    let text_buttons = this.element.getElementsByClassName("grs-select-text");
    let definition_element = this.element.getElementsByClassName(
      "non-grs-anchor-definition"
    )[0];

    let new_value = e.target.innerHTML;
    if (e.target.dataset.type === "number") {
      console.log("NUMBER CHANGE");
      this.valueTarget.value = new_value;
      console.log("VALUE TEXT TARGET", this.valuetextTarget);
      this.valuetextTarget.value = "";
    } else if (e.target.dataset.type === "text") {
      console.log("TEXT CHANGE");
      this.valuetextTarget.value = new_value;
      this.valueTarget.value = null;
    }
    this.getDefinition(
      this.element.dataset.scale,
      new_value,
      definition_element
    );
    for (let i = 0; i < buttons.length; i++) {
      let button = buttons[i];
      button.className =
        button.innerHTML === new_value
          ? "grs-select grs-value-active"
          : "grs-select grs-value-multi-inline";
    }
    for (let i = 0; i < text_buttons.length; i++) {
      let button = text_buttons[i];
      button.className =
        button.innerHTML === new_value
          ? "grs-select-text grs-value-text-multiline-active"
          : "grs-select-text grs-value-multi-inline";
    }
    this.checkIfComplete();
  }
  checkboxValueChange(e) {
    let value = this.element.dataset.scorevalue;
    let text = this.element.dataset.scoretext;
    let current = this.valueTarget.value
      ? this.valueTarget.value
      : this.valuetextTarget.value;
    if (current) {
      this.valueTarget.value = null;
      this.valuetextTarget.value = null;
      let new_el = document.createElement("div");
      new_el.className = "non-grs-checked-checkbox";
      new_el.setAttribute(
        "data-action",
        "click->non-grs-edit#checkboxValueChange"
      );
      e.target.replaceWith(new_el);
    } else if (value) {
      this.valueTarget.value = value;
      let new_el = document.createElement("i");
      new_el.className = "fa fa-check non-grs-checked-checkbox";
      new_el.setAttribute(
        "data-action",
        "click->non-grs-edit#checkboxValueChange"
      );
      e.target.replaceWith(new_el);
    } else if (text) {
      this.valuetextTarget.value = text;
      let new_el = document.createElement("i");
      new_el.className = "fa fa-check non-grs-checked-checkbox";
      new_el.setAttribute(
        "data-action",
        "click->non-grs-edit#checkboxValueChange"
      );
      e.target.replaceWith(new_el);
    }
    this.checkIfComplete();
    console.log("CURRENT", current);
    console.log("VALUE", value);
    console.log("TEXT", text);
    console.log("CLICKED", e.target);
  }

  checkIfComplete() {
    let complete = true;
    let items = document.getElementsByClassName("non-grs-section-items");
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      const inputsWithDataTarget = item.querySelectorAll("input[data-target]");
      let has_value = false;
      for (let i = 0; i < inputsWithDataTarget.length; i++) {
        const input = inputsWithDataTarget[i];
        if (input.value) {
          has_value = true;
        } else {
        }
      }
      let question_info =
        item.getElementsByClassName("question-info")[0].innerText;
      let mandatory = question_info.includes("Mandatory");

      if (mandatory && !has_value) {
        complete = false;
      }
    }
    let submit_button = document.getElementById("form-submit");
    if (submit_button) {
      submit_button.disabled = !complete;
    }
    let mandatory_message = document.getElementById("mandatory-text");
    if (!complete && mandatory_message) {
      mandatory_message.style.display = "block";
    } else if (mandatory_message) {
      mandatory_message.style.display = "none";
    }

    console.log("COMPLETE", complete);
  }

  getDefinition(scale_template_id, select_value, element) {
    console.log("GET DEFINITION", scale_template_id, select_value, element);
    if (select_value == "N/A") {
      element.innerHTML = "";
      return;
    }
    Rails.ajax({
      url: `/scale_templates/${scale_template_id}.json`,
      type: "get",
      success: function (e) {
        console.log("RESULSTS", e);
        let values = e.rating_template.scale_value_templates;
        let value = values.filter((i) => i.value === parseInt(select_value))[0];
        if (!value) {
          value = values.filter((i) => i.anchor_text === select_value)[0];
        }

        let div = document.createElement("div");
        let text = document.createTextNode(
          value.anchor_text ? value.anchor_text : ""
        );
        let definition = document.createTextNode(
          value.definition ? ` ${value.definition}` : ""
        );
        let strong = document.createElement("strong");
        strong.appendChild(text);
        div.appendChild(strong);
        div.appendChild(definition);
        element.innerHTML = "";
        element.appendChild(div);
      },
    });
  }
}
