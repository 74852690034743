import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["scaleid", "value", "valuetext", "definition", "anchor"];

  connect() {
    console.log("Hello, Field Ratings!", this.element);
  }

  valueChange(e) {
    console.log("Value changed", e.target);
    let button = e.target.closest(".fr-button");
    let type = button.getAttribute("data-type");
    let value = button.getAttribute("data-numval");
    let definition = button.getAttribute("data-definition");
    let anchor = button.getAttribute("data-anchor");
    let className = button.getAttribute("class");
    let scaleid = button.dataset.scaleid;
    this.scaleidTarget.value = scaleid;
    if (type === "number") {
      let selected_value = parseInt(value);
      console.log("SELECTED VALUE", selected_value);
      this.valueTarget.innerHTML = value;
      this.valueTarget.value = parseInt(value);
      this.valuetextTarget.value = anchor;
    } else {
      this.valuetextTarget.innerHTML = value;
      this.valueTarget.value = value;
    }
    if (definition) {
      this.definitionTarget.innerHTML = definition;
    } else {
      this.definitionTarget.innerHTML = "";
    }
    if (anchor) {
      this.anchorTarget.innerHTML = anchor;
    } else {
      this.anchorTarget.innerHTML = "";
    }
    console.log("CLASSNAME", className);
    let allButtons = this.element.querySelectorAll(
      `.${className.split(" ")[0]}`
    );
    console.log("BUTTONSA", allButtons);
    allButtons.forEach((button) => {
      button.classList.remove("fr-button-active");
    });
    button.classList.add("fr-button-active");
  }
}
