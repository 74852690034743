import { Controller } from "stimulus";

export default class extends Controller {
  connect(e) {
    let root = this.element;
    let area_id = root.getElementsByTagName("textarea")[0].id;
    let array = area_id.split("_");
    let unique_id = array[4];
    let btn = root.getElementsByTagName("button")[0];
    btn.id = `btn${unique_id}`;
    btn.dataset.target = `#${unique_id}`;
    let modal = root.getElementsByClassName("modal")[0];
    modal.id = unique_id;
    modal.getElementsByTagName("button")[1].dataset.modalid = unique_id;
  }

  selected(e) {
    e.preventDefault();
    let ecg_id = e.target.dataset.ecgid;
    let ecg_name = e.target.dataset.ecgname;
    let ecg_url = e.target.dataset.url;
    let modal_id = e.target.closest(".fade").id;
    let image_div = document.createElement("div");
    let image = document.createElement("img");
    image_div.appendChild(image);
    image.src = ecg_url;
    image.style.height = "200px";
    image.style.width = "auto";
    let row = this.element;
    let input_field = row.getElementsByClassName("ecg-id-field")[0];
    let ecg_name_el = row.getElementsByClassName("ecg-name")[0];
    input_field.value = ecg_id;
    ecg_name_el.innerHTML = ecg_name;
    ecg_name_el.append(image_div);
    $(`#${modal_id}`).modal("hide");
  }

  selectedpic(e) {
    e.preventDefault();
    let picture_id = e.target.dataset.pictureid;
    let picture_name = e.target.dataset.picturename;
    let picture_url = e.target.dataset.url;
    let modal_id = e.target.closest(".fade").id;
    let image_div = document.createElement("div");
    let image = document.createElement("img");
    image_div.appendChild(image);
    image.src = picture_url;
    image.style.height = "200px";
    image.style.width = "auto";
    let row = this.element;
    let input_field = row.getElementsByClassName("picture-id-field")[0];
    let picture_name_el = row.getElementsByClassName("picture-name")[0];
    input_field.value = picture_id;
    picture_name_el.innerHTML = picture_name;
    picture_name_el.append(image_div);
    $(`#${modal_id}`).modal("hide");
  }
}
