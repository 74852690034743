import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["table"];

  connect() {
    console.log("Hello, SDP REPORT!", this.element);
  }

  valueSelected = () => {
    let selected_cohort = document.getElementById("cohorts-selector").value;

    // Return the fetch Promise directly
    return fetch(`/self_directed_practices_by_cohort/${selected_cohort}.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  };

  renderTable(data) {
    const table = this.tableTarget;
    table.innerHTML = ""; // Clear existing table content

    // Create table headers
    const headerRow = table.insertRow();
    const headers = [
      "Student Name",
      "Scenario Practice",
      "Lifting",
      "Skill Practice",
    ];
    headers.forEach((headerText) => {
      let header = document.createElement("th");
      header.textContent = headerText;
      header.classList.add("sdp-header-cell");
      headerRow.appendChild(header);
    });

    // Create table rows
    data.forEach((student) => {
      const row = table.insertRow();

      // Student Name
      const nameCell = row.insertCell();
      nameCell.textContent = `${student.last_name} ${student.first_name}`;
      nameCell.classList.add("sdp-name-cell");

      // Scenario Practice, Lifting, and Skill Practice
      ["Scenario Practice", "Lifting", "Skill Evaluations"].forEach((type) => {
        const cell = row.insertCell();
        cell.classList.add("sdp-practice-cell");
        const relevantPractices = student.self_directed_practices.filter(
          (p) => p.type === type
        );

        // Count completed practices
        const completedCount = relevantPractices.length;
        const completedText =
          completedCount > 0
            ? `<strong>${completedCount} COMPLETED</strong>`
            : "<strong>0 COMPLETED</strong>";

        // Create links for practice names
        const practiceLinks = relevantPractices
          .map((p) => {
            const truncatedName =
              p.name.length > 30 ? `${p.name.substring(0, 30)}...` : p.name;
            return `<a href="/self_directed_practices/${p.id}" title="${p.name}"> - ${truncatedName}</a>`;
          })
          .join("<br>");

        // Combine count with practice links, adding a line break after the count
        cell.innerHTML =
          completedText + (completedCount > 0 ? ":<br>" + practiceLinks : "");
      });
    });
  }

  dateChanged = async () => {
    let api_payload = await this.valueSelected();
    let from_date = new Date(document.getElementById("from-date-picker").value);
    let to_date = new Date(document.getElementById("to-date-picker").value);

    // Filter and sort the self_directed_practices based on the selected dates
    let filtered_payload = api_payload.map((candidate) => {
      let filtered_and_sorted_practices = candidate.self_directed_practices
        .filter((practice) => {
          let practice_date = new Date(practice.date);
          return practice_date >= from_date && practice_date <= to_date;
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sorting in descending order

      return {
        ...candidate,
        self_directed_practices: filtered_and_sorted_practices,
      };
    });
    this.renderTable(filtered_payload);

    console.log("Filtered and sorted API payload", filtered_payload);
    console.log("date changed from", from_date, "to", to_date);
  };
}
