// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {
    console.log("Hello, RATING COMPETENCIES", this.element);
  }
  checkCompetency(event) {
    event.preventDefault();
    let item = event.target.closest(".list-group-item");
    let candidateId = item.dataset.userid;
    let ratingId = item.dataset.ratingid;
    let nocpId = item.dataset.compid;
    let raterId = item.dataset.raterid;
    let check_buttons = item.querySelectorAll(".check-button");
    let rating_type = item.dataset.ratingtype;
    console.log("Button text", event.target.innerText);
    let success_level =
      event.target.innerText == "DONE" ? "Successful" : "Needs Work";
    console.log("ITEM", item);
    console.log("Buttons", check_buttons);
    console.log("SUccess level", success_level);
    check_buttons.forEach((button) => {
      button.classList.add("hidden");
    });
    if (success_level == "Successful") {
      item.querySelectorAll(".successful")[0].classList.remove("hidden");
    } else if (success_level == "Needs Work") {
      item.querySelectorAll(".needs-work")[0].classList.remove("hidden");
    }

    let payload = {
      checked_competency: {
        rating_id: rating_type == "simulation" ? ratingId : null,
        skill_rating_id: rating_type == "skill" ? ratingId : null,
        candidate_id: candidateId,
        nocp_id: nocpId,
        rater_id: raterId,
        successful: success_level == "Successful" ? true : false,
        success_level: success_level,
        performance_environment: "simulation",
        type: "CheckedNocp",
        attempt_date: new Date(),
        date_of_rating: new Date(),
      },
    };
    let url = "/v1/checked_competencies.json";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        item.dataset.checkedid = data.id;
      });

    console.log("Checking competency", event.target);
  }
  uncheckCompetency(event) {
    event.preventDefault();
    let item = event.target.closest(".list-group-item");
    let checkedId = item.dataset.checkedid;
    let check_buttons = item.querySelectorAll(".check-button");
    check_buttons.forEach((button) => {
      button.classList.remove("hidden");
    });
    item.querySelectorAll(".successful")[0].classList.add("hidden");
    item.querySelectorAll(".needs-work")[0].classList.add("hidden");
    let url = `/v1/checked_competencies/${checkedId}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      });
    console.log("Unchecking competency", event.target);
  }
}
