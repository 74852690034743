// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {
    console.log("Hello, Sim Rating!", this.element);
    console.log("Rating Tool: ", this.element.dataset.ratingtool);
    setTimeout(() => this.updateRatingProgress(), 1000);
  }
  setActiveButton(event) {
    let active = event.target;
    let currentlyActive = this.element.querySelector(
      ".sim-rating-button-active"
    );
    if (currentlyActive) {
      currentlyActive.classList.remove("sim-rating-button-active");
    }
    active.classList.add("sim-rating-button-active");
  }
  handleButtonPress(event) {
    this.setActiveButton(event);
    let html = event.target.dataset.html;
    let display = this.element.querySelector(".sim-rating-scenario-info");
    display.innerHTML = html;
  }
  checkNonGrsProgress() {
    let complete = true;
    let completed_mandatory_items = 0;
    let mandatory_items = 0;
    let items = document.getElementsByClassName("non-grs-section-items");
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      const inputsWithDataTarget = item.querySelectorAll("input[data-target]");
      let has_value = false;
      for (let i = 0; i < inputsWithDataTarget.length; i++) {
        const input = inputsWithDataTarget[i];
        if (input.value) {
          has_value = true;
        } else {
        }
      }
      let question_info =
        item.getElementsByClassName("question-info")[0].innerText;
      console.log("QUESTION INFO", question_info);
      console.log("HAS Mandatory", question_info.includes("Mandatory"));
      let mandatory = question_info.includes("Mandatory");
      mandatory_items = mandatory ? mandatory_items + 1 : mandatory_items;
      console.log("MANDATORY ITEMS", mandatory_items);
      if (mandatory && !has_value) {
        complete = false;
      }
      completed_mandatory_items =
        mandatory && has_value
          ? completed_mandatory_items + 1
          : completed_mandatory_items;
    }
    console.log("COMPLETED MANDATORY ITEMS", completed_mandatory_items);
    console.log("MANDATORY ITEMS", mandatory_items);
    let progress = Math.floor(
      (completed_mandatory_items / mandatory_items) * 100
    );
    this.updateUiBasedOnProgress(progress);
    // let submit_button = document.getElementById("form-submit");
    // submit_button.disabled = !complete;
    // let mandatory_message = document.getElementById("mandatory-text");
    // if (!complete) {
    //   mandatory_message.style.display = "block";
    // } else {
    //   mandatory_message.style.display = "none";
    // }

    console.log("PROGRESS", progress);
  }
  updateRatingProgress(event) {
    console.log("Updating Rating Progress");
    let rating_tool = this.element.dataset.ratingtool;

    if (rating_tool !== "9999999") {
      this.checkNonGrsProgress();
    } else {
      let mandatory_fields = document.querySelectorAll(".mandatory-field");
      mandatory_fields = Array.from(mandatory_fields);
      let filled_fields = mandatory_fields.filter(
        (field) => field.value !== ""
      );
      let total_fields = mandatory_fields.length;
      let filled = filled_fields.length;
      let progress = Math.floor((filled / total_fields) * 100);
      this.updateUiBasedOnProgress(progress);
    }
  }
  updateUiBasedOnProgress(progress) {
    console.log("updateing progress bar");
    let progressBar = this.element.querySelector("#rating-progress-bar");
    progressBar.style.width = `${progress}%`;

    if (progress === 100) {
      let submit_button = document.getElementById("form-submit");
      console.log("SUBMIT BUTTON", submit_button);
      submit_button.disabled = false;
      progressBar.style.backgroundColor = "#28a745";
    } else {
      progressBar.classList.remove("bg-success");
    }
  }
}
