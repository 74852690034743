import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cohortSelect", "status", "buttonDropdown", "spinner"];

  connect() {
    console.log("nocp map connected");
    $("#initial").show();
    $("#loading").hide();
    console.log($("#loadingbar").html);
    $("#loadingbar").hide();
  }

  statusButton(e) {
    $("#smallModal").modal("show");

    let dropdown_id = event.target.getAttribute("dropdown-id");
    let nocp_id = event.target.getAttribute("nocp");
    let candidate_id = event.target.getAttribute("candidate");
    console.log(
      `NOCP is ${event.target.getAttribute(
        "nocp"
      )} and  Candidate is ${event.target.getAttribute("candidate")}`
    );

    Rails.ajax({
      url: `/populate_dropdown.js?nocp_id=${nocp_id}&candidate_id=${candidate_id}&dropdown_id=${dropdown_id}`,
      type: "get",
      success: function() {}
    });
  }

  vloStatusButton(e) {
    $("#smallModal").modal("show");

    let dropdown_id = event.target.getAttribute("dropdown-id");
    let vlo_id = event.target.getAttribute("vlo");
    let candidate_id = event.target.getAttribute("candidate");
    console.log(
      `VLO is ${event.target.getAttribute(
        "vlo"
      )} and  Candidate is ${event.target.getAttribute("candidate")}`
    );

    Rails.ajax({
      url: `/populate_vlo_dropdown.js?vlo_id=${vlo_id}&candidate_id=${candidate_id}&dropdown_id=${dropdown_id}`,
      type: "get",
      success: function() {}
    });
  }

  vloSelect() {
    console.log("vlo selected!!");
    $("#initial").hide();
    $("#nocp-map").hide();
    $("#loading").show();
    let cohort_id = this.cohortSelectTarget.options[
      this.cohortSelectTarget.selectedIndex
    ].value;

    Rails.ajax({
      url: `/vlo_map_select.js?cohort_id=${cohort_id}`,
      type: "get",
      success: function(e) {
        $("#loading").hide();
        $("#nocp-map").show();
        console.log($(".nocp-status-button").first());
      }
    });
  }

  envSelect() {
    function checkboxes() {
      console.log(this.cohortSelectTargets);
    }
    console.log("selected!!");
    $("#initial").hide();
    $("#nocp-map").hide();
    $("#loading").show();
    let cohort_id = this.cohortSelectTarget.options[
      this.cohortSelectTarget.selectedIndex
    ].value;
    let selected_env = event.target.options[event.target.selectedIndex].text;
    Rails.ajax({
      url: `/nocp_map_select.js?cohort_id=${cohort_id}&selected_env=${selected_env}`,
      type: "get",
      success: function(e) {
        $("#loading").hide();
        $("#nocp-map").show();
        console.log($(".nocp-status-button").first());
      }
    });
  }
}
