// app/javascript/controllers/check_competencies_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  checkCompetencies(event) {
    console.log("This element", this.element.dataset);
    event.preventDefault(); // Prevent the form from submitting immediately
    let rating_id = this.element.dataset.id;
    let rating_type = this.element.dataset.type;

    const url = `/v1/checked_competencies/check_if_record_has_checked_comps?parent_id=${rating_id}&parent=${rating_type}`; // Set this to your API endpoint
    fetch(url, {
      method: "GET", // or 'POST' if your API requires it
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"), // Include CSRF token for Rails security
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.has_checked_comps) {
          this.formTarget.submit(); // If the check is true, submit the form
        } else {
          $("#competencyCheckModal").modal("show");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while checking competencies.");
      });
  }
}
document.addEventListener("DOMContentLoaded", function () {
  document
    .getElementById("continueSubmission")
    .addEventListener("click", function () {
      $("#competencyCheckModal").modal("hide");
      document.querySelector('[data-target="check-comps.form"]').submit();
    });
});
// document.addEventListener("DOMContentLoaded", function () {
//   document
//     .getElementById("cancelSubmission")
//     .addEventListener("click", function () {
//       $("#competencyCheckModal").modal("hide");
//       //get submit button
//       let submitButton = document.querySelector("#form-submit");
//       submitButton.disabled = false;
//     });
// });
