import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["ratingItem", "scaleItem", "button"];
  connect() {
    console.log("connected");
  }

  completed(e) {
    var items = $(this.ratingItemTargets).length;
    console.log("ITEMS", items);
    var completed = $(this.scaleItemTargets).filter(":checked").length;
    if (items == completed) {
      var button = $(this.buttonTarget).find(".btn");
      button.prop("value", "SUBMIT");
      button.removeClass("btn-primary");
      button.addClass("btn-success");
      button.removeClass("btn-md");
      button.addClass("btn-lg");
    }
  }
}
