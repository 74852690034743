import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["anchor", "definition"];

  connect() {
    console.log("Hello, fieldrating template!", this.element);
  }
  valueChange(e) {
    let button = e.target.closest(".fr-button");
    console.log("Value changed", button);
    this.anchorTarget.innerHTML = button.getAttribute("data-anchor");
    this.definitionTarget.innerHTML = button.getAttribute("data-definition");
  }
}
