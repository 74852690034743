import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {
    console.log("Hello, OPENAI!");
  }
  //change to async
  async fetchIncidentHistory(e) {
    let prompt = e.target.dataset.prompt;
    const messageContent = `Write a the incident history for a simulation scenario for paramedics. The information should be for the paramedics when they arrive on scene. It should describe ${prompt} without mentioning anything about diagnosis, past medical history or vital signs or treatment.`;
    this.toggleLoading(true); // Show loading div
    let text = await this.fetchGeneratedText(messageContent);
    this.toggleLoading(false); // Hide loading div
    document.getElementById("simulation_incident_history").value = text;
    this.fetchOngoingMedicalIssues(text);
  }

  async fetchVitalSigns() {
    console.log("fetchVitalSigns");
    let incident_history = this.element.dataset.caseHistory;
    let count = document.getElementById("vital_count").value;
    let status = document.getElementById("status").value;
    let vitals_json_format =
      "{'time: 5 minutes after arrival', 'blood_sugar': Float (in mmol/L), 'heart_rate': Integer, 'skin': String, capillary_refill: String, 'respiratory_rate': Integer, 'systolic_bp': Integer,'diastolic_bp': Integer, 'oxygen_saturation': Integer, 'temperature': Float(celsius), 'etco2': Integer, 'gcs': { 'eyes': Integer, 'verbal': Integer, 'motor': Integer} }";
    let messageContent = `Write a list of ${count} ${status} vital signs 5 minutes apart in a json array with this format\n${vitals_json_format} that are appropriate for the case described here\n${incident_history}.`;
    this.toggleLoading(true); // Show loading div
    let text = await this.fetchGeneratedText(messageContent);
    this.toggleLoading(false); // Hide loading div
    let vitals_array = JSON.parse(text);
    for (let i = 0; i < vitals_array.length; i++) {
      let vital = vitals_array[i];
      //get a list of the 'add_fields' links
      let add_fields = document.querySelectorAll(".add_fields");
      //click the last one
      add_fields[add_fields.length - 1].click();
      let fields = document.querySelectorAll(".nested-fields");
      let last_fields = fields[fields.length - 1];
      let time_wrapper = last_fields.querySelector(
        ".simulation_vital_signs_time"
      );
      //find the input field in the wrapper
      time_wrapper.querySelector("input").value = vital.time;
      //set the value
      let heart_rate_wrapper = last_fields.querySelector(
        ".simulation_vital_signs_pulse"
      );
      //find the input field in the wrapper
      heart_rate_wrapper.querySelector("input").value = vital.heart_rate;
      //set the value
      let respiratory_rate_wrapper = last_fields.querySelector(
        ".simulation_vital_signs_respirations"
      );
      //find the input field in the wrapper
      respiratory_rate_wrapper.querySelector("input").value =
        vital.respiratory_rate;
      //set the value
      let systolic_bp_wrapper = last_fields.querySelector(
        ".simulation_vital_signs_systolic_bp"
      );
      //find the input field in the wrapper
      systolic_bp_wrapper.querySelector("input").value = vital.systolic_bp;
      //set the value
      let diastolic_bp_wrapper = last_fields.querySelector(
        ".simulation_vital_signs_diastolic_bp"
      );
      //find the input field in the wrapper
      diastolic_bp_wrapper.querySelector("input").value = vital.diastolic_bp;
      //set the value
      let oxygen_saturation_wrapper = last_fields.querySelector(
        ".simulation_vital_signs_spo2"
      );
      //find the input field in the wrapper
      oxygen_saturation_wrapper.querySelector("input").value =
        vital.oxygen_saturation;
      //set the value
      let temperature_wrapper = last_fields.querySelector(
        ".simulation_vital_signs_temperature"
      );
      //find the input field in the wrapper
      temperature_wrapper.querySelector("input").value = vital.temperature;
      //set the value
      let etco2_wrapper = last_fields.querySelector(
        ".simulation_vital_signs_etco2"
      );
      //find the input field in the wrapper
      etco2_wrapper.querySelector("input").value = vital.etco2;
      //set the value
      let gcs_wrapper_e = last_fields.querySelector(
        ".simulation_vital_signs_gcs_e"
      );
      let gcs_selector_e = gcs_wrapper_e.querySelector("select");
      gcs_selector_e.value = vital.gcs.eyes;
      let gcs_wrapper_v = last_fields.querySelector(
        ".simulation_vital_signs_gcs_v"
      );
      let gcs_selector_v = gcs_wrapper_v.querySelector("select");
      gcs_selector_v.value = vital.gcs.verbal;
      let gcs_wrapper_m = last_fields.querySelector(
        ".simulation_vital_signs_gcs_m"
      );
      let gcs_selector_m = gcs_wrapper_m.querySelector("select");
      gcs_selector_m.value = vital.gcs.motor;
      let skin_wrapper = last_fields.querySelector(
        ".simulation_vital_signs_skin"
      );
      //find the input field in the wrapper
      skin_wrapper.querySelector("input").value = vital.skin;
      //set the value
      let capillary_refill_wrapper = last_fields.querySelector(
        ".simulation_vital_signs_cap_refill"
      );
      //find the input field in the wrapper
      capillary_refill_wrapper.querySelector("input").value =
        vital.capillary_refill;
      //set the value
      let blood_sugar_wrapper = last_fields.querySelector(
        ".simulation_vital_signs_blood_sugar"
      );
      //find the input field in the wrapper
      blood_sugar_wrapper.querySelector("input").value = vital.blood_sugar;
      //set the value
    }
    console.log("RESULT", JSON.parse(text));
  }

  async fetchOngoingMedicalIssues(prompt) {
    const messageContent = `given this fictional patient \n ${prompt} \nwrite a list in html format of ongoing medical issues that lead up to this event. List them in point form without elaborating on them. It is OK to write nothing.`;
    this.toggleLoading(true); // Show loading div
    let text = await this.fetchGeneratedText(messageContent);
    this.toggleLoading(false); // Hide loading div
    document.getElementById("simulation_narrative_health_history").value = text;
    console.log("text", text);
  }

  toggleLoading(isLoading) {
    const loadingDiv = document.getElementById("loading");

    if (isLoading) {
      loadingDiv.style.display = "block"; // Show the loading div
    } else {
      loadingDiv.style.display = "none"; // Hide the loading div
    }
  }

  async fetchGeneratedText(messageContent) {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer 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"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      model: "gpt-4",
      messages: [
        {
          role: "user",
          content: messageContent,
        },
      ],
      temperature: 0.7,
      max_tokens: 2000,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://api.openai.com/v1/chat/completions",
      requestOptions
    );
    const result_1 = await response.text();
    let json = JSON.parse(result_1);
    console.log("JSON", json);
    let text = json.choices[0].message.content;
    return text;
  }
}
