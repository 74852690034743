import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("Back Controller connected");
  }

  checkEdit(event) {
    let element = this.element;

    let isEdited = document
      .getElementById("page-wrap")
      .getAttribute("data-edited");

    if (isEdited === "true") {
      let shouldSubmit = confirm("Do you want to save your edits?");
      if (shouldSubmit) {
        document.querySelector("#nocp-form").submit();
      } else {
        // Allow back button default behavior
        return;
      }
      event.preventDefault();
    } else {
      // Allow back button default behavior
      return;
    }
  }
}
