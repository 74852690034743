// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item"];

  connect() {
    this.hideCompleted();
    this.getRecordCount();
  }

  getRecordCount() {
    let count = document.querySelectorAll("li.revealed").length;
    document.getElementById("rec-count").innerHTML = `${count} comps.`;
    console.log("COUNT", count);
  }

  setCompletedNocpsToResults(item) {
    let setto = item.dataset.setto;
    let label = item.getElementsByClassName("submit-label")[0];
    if (setto == "Successful") {
      item.classList.add("list-group-item-success");
      item.classList.add("completed");
      label.innerHTML = "Successful";
    } else if (setto == "Needs Work") {
      item.classList.add("list-group-item-warning");
      item.classList.add("completed");
      label.innerHTML = "Needs Work";
    }
  }

  hideCompleted() {
    document.querySelectorAll(".completed-icon").forEach((element) => {
      let closestLi = element.closest("li");
      closestLi.classList.add("hidden");
      closestLi.classList.remove("revealed");
    });
  }

  unhideCompleted() {
    document.querySelectorAll("li.hidden").forEach((element) => {
      element.classList.remove("hidden");
      element.classList.add("revealed");
    });
  }

  toggleHideCompleted = (event) => {
    let indicator = document.querySelector("#comp-hide-unhide").innerHTML;
    if (indicator === "Unhide Completed") {
      this.unhideCompleted();
      this.setHideUnhideMessage("unhidden");
    } else {
      this.hideCompleted();
      this.setHideUnhideMessage("hidden");
    }
    this.getRecordCount();
  };

  setHideUnhideMessage = (status) => {
    var element = document.querySelector("#comp-hide-unhide");
    if (status == "hidden") {
      element.innerHTML = "Unhide Completed";
    } else {
      element.innerHTML = "Hide Completed";
    }
  };
}
