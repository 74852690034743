import { Controller } from "stimulus";
import moment from "moment";
export default class extends Controller {
  connect() {
    console.log("Hello, ATTENDANCE");
    this.changed();
  }
  changed() {
    let start_time = document.getElementById("start-time");
    let start_date = document.getElementById("start-date").value;
    let start_time_hr_select = start_time.querySelectorAll("select")[0];
    let start_time_min_select = start_time.querySelectorAll("select")[1];
    let start_hr =
      start_time_hr_select.options[start_time_hr_select.options.selectedIndex]
        .text;
    let start_min =
      start_time_min_select.options[start_time_min_select.options.selectedIndex]
        .text;
    let end_time = document.getElementById("end-time");
    let end_date = document.getElementById("end-date").value;
    let end_time_hr_select = end_time.querySelectorAll("select")[0];
    let end_time_min_select = end_time.querySelectorAll("select")[1];
    let end_hr =
      end_time_hr_select.options[end_time_hr_select.options.selectedIndex].text;
    let end_min =
      end_time_min_select.options[end_time_min_select.options.selectedIndex]
        .text;
    let end_timestamp = moment(`${end_date} ${end_hr}:${end_min}`);
    let start_timestamp = moment(`${start_date} ${start_hr}:${start_min}`);
    let duration = moment.duration(end_timestamp.diff(start_timestamp));
    let duration_hours = duration.as("hours");
    document.getElementById(
      "hour-display"
    ).innerHTML = `${duration_hours} hours`;
    if (parseFloat(duration_hours) < 2 || parseFloat(duration_hours) > 16) {
      document.getElementById("hour-display").classList.add("attendance-error");
      document.getElementById("save-btn").disabled = true;
    } else {
      document
        .getElementById("hour-display")
        .classList.remove("attendance-error");
      document.getElementById("save-btn").disabled = false;
    }
    console.log("HOURS");
    console.log("end-hr", end_hr);
    console.log("start-hr", start_hr);
    console.log("start-min", start_min);
    console.log("start-date", start_date);
    console.log("end-hr", end_hr);
    console.log("end-min", end_min);
    console.log("end-date", end_date);
    console.log("sart time stamp", start_timestamp.isValid());
  }
}
