import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];
  test(e) {
    console.log("test has been clicked");
    Rails.ajax({
      url: "/field_experiences_list/1",
      type: "get",
      dataType: "json",
      success: function(json) {
        console.log(json);
        $.each(json, function(i, value) {
          $("#field_experience").append(
            $("<option>")
              .text(value.name)
              .attr("value", value.id)
          );
        });
      }
    });
  }
}
