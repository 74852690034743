import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    console.log("Hello, RATING TOOL");
  }

  checked(e) {
    console.log("Hello, CKLICKY", e);
    let btn = document.getElementById("submit");
    btn.removeAttribute("disabled");
  }

  changed(e) {
    console.log("SELECTED", this.element.dataset.sim);
    $.ajax({
      url: "/rating_tools_select.js",
      method: "PUT",
      data: JSON.stringify({
        simulation: {
          sim_id: this.element.dataset.sim,
          checked: e.target.checked,
          tool_id: e.target.value,
        },
      }),
      contentType: "application/json",
      success: function(response) {
        console.log("SUCCESS", response);
      },
    });
  }
}
