import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("Hello, Waaveforms!");
    let toggle_button = this.element.getElementsByClassName("toggle-button")[0];
    let modal = this.element.getElementsByClassName("modal")[0];
    let ecg_name_input = this.element.getElementsByClassName("ecg-input")[0];
    let waveform_id_input = this.element.getElementsByClassName(
      "waveform-id"
    )[0];
    let data_row = this.element.getElementsByClassName("info-row")[0];
    let image_tag = this.element.getElementsByClassName("image-tag")[0];

    let id = Math.floor(Math.random() * 1000000000);
    data_row.dataset.input = id;
    modal.id = id;
    modal.classList.add(`bs-example-modal-lg${id}`);
    ecg_name_input.classList.add(`ecg-${id}`);
    toggle_button.dataset.target = `.bs-example-modal-lg${id}`;
    waveform_id_input.classList.add(`${id}`);
    image_tag.id = `img${id}`;

    console.log("BTN", toggle_button);
  }

  select(e) {
    let wrapper = e.target.closest(".list-group-item");
    let wave_id = wrapper.dataset.id;
    let wave_url = wrapper.dataset.url;
    let info_row = e.target.closest(".info-row");
    let input_class = info_row.dataset.input;
    console.log("INOPUT", input_class);
    let name = wrapper.dataset.name;
    let ecg_name = document.getElementsByClassName(`ecg-${input_class}`)[0];
    console.log("ECG NAME FIELD", ecg_name);
    let image_tag = document.getElementById(`img${input_class}`);
    console.log("IMAGE_TAG", image_tag);
    image_tag.src = wave_url;
    let input = document.getElementsByClassName(input_class)[0];
    ecg_name.value = name;
    input.value = wave_id;
    console.log("Selected", input);
    $(`#${input_class}`).modal("hide");
  }
}
